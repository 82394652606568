import { createResource, Match, Show, Switch } from "solid-js";
import SolidMarkdown from "solid-markdown";
import { ThreeDotLoader } from "~/widgets/button";
import { getBrandTncData } from "~/server/data/brand_tnc_data";
import { DividerV2 } from "~/widgets/divider";
import { CloseModalButton, ModalInnerFrame } from "~/components/modal";
import { Spacer } from "~/widgets/spacer";
import { convertUrlsToLinks, rehypeUppercaseATags } from "~/utils/common";
import rehypeRaw from "rehype-raw";

type TncsProps = {
  tncResId: string;
  subtitle?: string | undefined;
};

export function Tncs(props: TncsProps) {
  const [response, mutateResponse] = createResource<string>(async () => {
    return getBrandTncData(props.tncResId);
  });

  return (
    <ModalInnerFrame>
      <div class="flex h-fit flex-col items-start ">
        <div class="flex w-full flex-row  items-start justify-between ">
          <div class="  text-h3  text-textDark dark:text-textWhite ">
            Things to note
          </div>
          <CloseModalButton />
        </div>
        <Show when={props.subtitle}>
          <Spacer height={8} />
          <span class=" text-normal text-[#E78327]">{props.subtitle}</span>
        </Show>
        <Spacer height={16} />
        <DividerV2 class="bg-baseTertiaryMedium dark:bg-basePrimaryMedium" />
        <Spacer height={16} />
      </div>
      <div class="flex flex-col items-start justify-start gap-2 self-stretch text-normal  text-textDark dark:text-baseTertiaryLight ">
        <Switch>
          <Match when={response.state === "ready"}>
            <SolidMarkdown
              class="tnc-list"
              rehypePlugins={[rehypeUppercaseATags, rehypeRaw] as any}
              children={convertUrlsToLinks(response()!)}
              linkTarget={"_blank"}
            />
          </Match>
          <Match when={response.state === "pending"}>
            <ThreeDotLoader />
          </Match>
          <Match when={response.state === "errored"}>
            <div class="dark:text-textWhite">{response.error.message}</div>
          </Match>
        </Switch>
      </div>
    </ModalInnerFrame>
  );
}
